body {
    background-color: #eeeeee !important;
    margin: 0;
    padding: 0;
    height: 100%;
}

.logo128{
    width: 128px;
    float: left;
    margin-left: 15px;
    margin-right: 15px;
}

.logo64{
    width: 64px;
    float: left;
    margin-left: 5px;
    margin-right: 5px;
}

.up-table th, .up-table td {
    border-top: 0 !important;
}

.up-table .thead-up th{
color: #fff;
background-color: #003264;
border-color: #454d55;
vertical-align:middle;
}

.up-table tbody td{
    vertical-align:middle;
}

.up-table .thead-up th.highlight{
color: #003264;
background-color: #ffcc00;
border-color: #ffcc00;
}

.up-table{
border : 0;
}

.up-table tr.odd{
background-color: white;
}

.up-table tr.even{
background-color: #fafafa;
}


.up-nav{
display: flex;
background-color:  #003264;
font-size: 20px;
margin-bottom: 20px;
}

.up-nav-link{
color: white;
display: block;
padding: 0.5rem 1rem;
}

.up-nav-link:hover, .up-nav-link.active{
color: #003264;
background-color:  #ffcc00;
text-decoration: none;
}

.up-title{
color: #003264;
width: 100%;
}
.up-title .title{
margin-top: 12px;
}

.up-title .subtitle{
display: block;
font-size: 36px;
}

.up-search-title, .up-filter-title, .up-form-label{
font-size: 20px;
font-weight: bold;
}

.up-filter-option, .up-sale-info{
font-size: 18px;
}

.btn-up{
color: #003264 !important;
background-color: #ffcc00 !important;
border-color: #ffcc00 !important;
font-weight: bold !important;
}

.mt-6{
    margin-top: 6rem !important;
}

.btn-up-secondary{
color: #ffffff !important;
background-color: #003264 !important;
border-color: #003264 !important;
font-weight: bold !important;
}

.dropdown-menu.dropdown-up-menu{
background-color: #003264 !important;
}

.dropdown-item.dropdown-up-item.active, .dropdown-item.dropdown-up-item:hover{
color: #003264 !important;
background-color: #ffcc00 !important;
}

.dropdown-item.dropdown-up-item{
color: #ffffff !important;
background-color: #003264 !important;
}

.up-sales-title{
font-size: 24px;
font-weight: bold;
text-transform: uppercase;
}

.up-sale-number{
font-weight: bold;
color: #003264;
}



/******************* Page de Login **************************/

.login-title{
    text-align: center;
}

.user_card {
    width: 350px;
    margin-top: 150px;
    margin-bottom: auto;
    background: #ffcc00;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
}

.user_card-register{
    height: 500px;
}

.user_card-login{
    height: 400px;
}

.brand_logo_container {
    position: absolute;
    height: 170px;
    width: 170px;
    top: -75px;
    border-radius: 50%;
    background: #fefefe;
    padding: 10px;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.brand_logo {
    height: 150px;
    width: 150px;
}
.form_container {
    margin-top: 80px;
}
.login_btn {
    width: 100%;
    background: #003264 !important;
    color: white !important;
}
.login_btn:focus {
    box-shadow: none !important;
    outline: 0px !important;
}
.login_container {
    padding: 0 2rem;
}
.input-group-text {
    background: #003264 !important;
    color: white !important;
    border: 0 !important;
    border-radius: 0.25rem 0 0 0.25rem !important;
}
.input_user,
.input_pass:focus {
    box-shadow: none !important;
    outline: 0px !important;
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #003264 !important;
}


/********************* Page register *****************/
#playground-container {
    height: 500px;
    overflow: hidden !important;
    -webkit-overflow-scrolling: touch;
}

.main{
    margin:50px 15px;
}

h1.title {
    font-size: 50px;
    font-family: 'Passion One', cursive;
    font-weight: 400;
}

hr{
    width: 10%;
    color: #fff;
}

.form-group{
    margin-bottom: 15px;
}

label{
    margin-bottom: 15px;
}

input,
input::-webkit-input-placeholder {
    font-size: 11px;
    padding-top: 3px;
}

.main-login{
    background-color: #fff;
    /* shadows and rounded borders */
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);

}
.form-control {
    height: auto!important;
    padding: 8px 12px !important;
}
.input-group {
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.21)!important;
    -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.21)!important;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.21)!important;
}
#button {
    border: 1px solid #ccc;
    margin-top: 28px;
    padding: 6px 12px;
    color: #666;
    text-shadow: 0 1px #fff;
    cursor: pointer;
    -moz-border-radius: 3px 3px;
    -webkit-border-radius: 3px 3px;
    border-radius: 3px 3px;
    -moz-box-shadow: 0 1px #fff inset, 0 1px #ddd;
    -webkit-box-shadow: 0 1px #fff inset, 0 1px #ddd;
    box-shadow: 0 1px #fff inset, 0 1px #ddd;
    background: #f5f5f5;
    background: -moz-linear-gradient(top, #f5f5f5 0%, #eeeeee 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f5f5f5), color-stop(100%, #eeeeee));
    background: -webkit-linear-gradient(top, #f5f5f5 0%, #eeeeee 100%);
    background: -o-linear-gradient(top, #f5f5f5 0%, #eeeeee 100%);
    background: -ms-linear-gradient(top, #f5f5f5 0%, #eeeeee 100%);
    background: linear-gradient(top, #f5f5f5 0%, #eeeeee 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f5f5f5', endColorstr='#eeeeee', GradientType=0);
}
.main-center{
    margin-top: 30px;
    margin: 0 auto;
    max-width: 400px;
    padding: 10px 40px;
    background:#009edf;
    color: #FFF;
    text-shadow: none;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.31);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.31);
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.31);

}
span.input-group-addon i {
    color: #009edf;
    font-size: 17px;
}

.login-button{
    margin-top: 5px;
}

.login-register{
    font-size: 11px;
    text-align: center;
}